<!-- Subheader Start -->
<div class="header-1 header-absolute"></div>
<div class="subheader subheader-2 user-subheader bg-cover bg-center"
    style="background-image: url('assets/img/subheader-2.jpg')">
    <div class="container">
        <div class="media">
            <img src="assets/img/people/1.jpg" alt="agent">
            <div class="media-body">
                <h3 class="text-white">Randy Blue</h3>
                <span class="user-email">randy_blue@hotmail.com</span>
            </div>
            <a routerLink="/submit-listing" class="btn-custom secondary mr-0">Submit Listing <i
                    class="fas mr-0 fa-plus"></i> </a>
        </div>
    </div>
</div>
<!-- Subheader End -->