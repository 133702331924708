
export class RequestCallbackDto {
   id = 0;
   name="";
   email = "";
   phone = "";
   propertyId  = 0;
   propertyName = "";
   otp = 0;
   otpVerified = false;
   message = "";
   insertDate = new Date();
}
