<!-- Aside (Mobile Navigation) -->
<aside class="main-aside" [ngClass]="navmethod ? '' : 'open'">
    <div class="aside-title">
        <div class="aside-controls aside-trigger">
            <h4>Menu</h4>
            <div class="close-btn close-dark" (click)="toggleNav()">
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
    <app-mobilemenu></app-mobilemenu>
</aside>
<div class="aside-overlay aside-trigger" (click)="toggleNav()"></div>
<!-- Header Start -->
<header class="main-header header-3">
    <nav class="navbar">
        <div class="container">
            <app-menu></app-menu>
            <div class="header-controls">
                <!-- <ul class="header-controls-inner d-none d-lg-flex">
                    <li>
                        <a routerLink="/login" class="btn-custom primary">Login <i class="fas fa-user"></i> </a>
                    </li>
                </ul> -->
                <!-- Toggler -->
                <div class="aside-toggler aside-trigger" (click)="toggleNav()">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </nav>
</header>
<!-- Header End -->