import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestCallbackDto } from "../Models/request-callback-dto.model";

@Injectable({
  providedIn: 'root'
})

export class ApiService
{
  constructor(
    private readonly httpClient:HttpClient
  )
  {}



  saveCallBack(data : RequestCallbackDto):Observable<string>{
    return this.httpClient.post<string>('https://api.mouthpiececreatives.in/api/PropertyShow/SaveRequestCallBack',data);
  }
}
